<template>
  <el-dialog v-if="dialogVisible" :title="$t('tpLink.peoples')" :visible.sync="dialogVisible" width="1300px"
    top="5vh" class="small-padding">
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="tpLink/peopleList" @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$t('workOrder.alarmTime')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleChange">
          </el-date-picker>
        </vm-search>
      </template>
      <table-column prop="deviceName" :label="$t('tpLink.deviceName')" width="300px"
        align="center"></table-column>
      <table-column prop="elevatorName" :label="$t('tpLink.boundElevator')"></table-column>
      <table-column prop="peopleCount" :label="$t('tpLink.peoples')" width="200px"
        align="center"></table-column>
      <table-column prop="alarmTime" :label="$t('workOrder.alarmTime')" width="200px"
        align="center"></table-column>
      <table-column prop="createTime" :label="$l('workOrder.createTime', '创建时间')" width="200px"
        align="center"></table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dateRange: [],
      search: {
        devId: "",
        elevatorId: "",
        startTime: "",
        endTime: "",
      },
    };
  },
  methods: {
    open(devId) {
      this.dialogVisible = true;
      if (devId) {
        this.search.devId = devId;
      }
      this.$nextTick(() => {
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    onDialogClose() {
      this.dialogVisible = false;
    },
    handleChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>

<style></style>
